var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("form", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#speed-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "speed-filter"
            }
          },
          [_vm._v(" Maximum spin speed (rpm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "speed-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.maximumSpeeds,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select maximum speeds",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.maximumSpeedChanged },
              model: {
                value: _vm.maximumSpeed,
                callback: function($$v) {
                  _vm.maximumSpeed = $$v
                },
                expression: "maximumSpeed"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#capacity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "capacity-filter"
            }
          },
          [_vm._v(" Capacity (kg) - Washing ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "capacity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.capacitiesWashing,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select capacities",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.capacityWashingChanged },
              model: {
                value: _vm.capacityWashing,
                callback: function($$v) {
                  _vm.capacityWashing = $$v
                },
                expression: "capacityWashing"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#capacity-drying-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "capacity-drying-filter"
            }
          },
          [_vm._v(" Capacity (kg) - Drying ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "capacity-drying-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.capacitiesDrying,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select capacities",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.capacityDryingChanged },
              model: {
                value: _vm.capacityDrying,
                callback: function($$v) {
                  _vm.capacityDrying = $$v
                },
                expression: "capacityDrying"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.instTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select installation types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.installationChanged },
              model: {
                value: _vm.installation,
                callback: function($$v) {
                  _vm.installation = $$v
                },
                expression: "installation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#color-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "color-filter"
            }
          },
          [_vm._v(" Colour ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "color-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feet-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feet-filter"
            }
          },
          [_vm._v(" Adjustable feet ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feet-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.adjustableFeets,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select adjustable feets",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.adjustableFeetChanged },
              model: {
                value: _vm.adjustableFeet,
                callback: function($$v) {
                  _vm.adjustableFeet = $$v
                },
                expression: "adjustableFeet"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "connectivity-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#loading-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "loading-filter"
            }
          },
          [_vm._v(" Loading type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "loading-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.loadingTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select loading types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.loadingTypeChanged },
              model: {
                value: _vm.loadingType,
                callback: function($$v) {
                  _vm.loadingType = $$v
                },
                expression: "loadingType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#hinge-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "hinge-filter"
            }
          },
          [_vm._v(" Cycle options ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "hinge-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.cycleOptions,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select cycle options",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.cycleOptionChanged },
              model: {
                value: _vm.cycleOption,
                callback: function($$v) {
                  _vm.cycleOption = $$v
                },
                expression: "cycleOption"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#hinge-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "hinge-filter"
            }
          },
          [_vm._v(" Door hinge ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "hinge-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.doorHinges,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select door hinges",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.doorHingeChanged },
              model: {
                value: _vm.doorHinge,
                callback: function($$v) {
                  _vm.doorHinge = $$v
                },
                expression: "doorHinge"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key feature ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.display,
                        expression: "display"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.display)
                        ? _vm._i(_vm.display, null) > -1
                        : _vm.display
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.display,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.display = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.display = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.display = $$c
                          }
                        },
                        _vm.displayChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("Display")
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }